import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Bold } from "../structure/theme/styles.component"
import EllipsisLoader from "../structure/theme/ellipsisLoader.component"

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`

const Label = styled.div`
  flex: 1;
  margin-right: 5px;
`

const Value = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export default function SummaryLine ({ label, value }) {
  if (!value && value !== undefined) {
    return null
  }

  const _value = Array.isArray(value) ? [...value] : [value]

  return (
    <Wrapper>
      <Label data-testid="payment_line_label">
        <Bold>{label}</Bold>
      </Label>
      <Value data-testid="payment_line_value">
        {_value
          .filter(v => v || v === undefined)
          .map((val, index) => (
            <div key={index}>{val === undefined ? <EllipsisLoader /> : val}</div>
          ))}
      </Value>
    </Wrapper>
  )
}

SummaryLine.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
}
