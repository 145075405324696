import React, { memo } from "react"
import PropTypes from "prop-types"
import { useCampaignProduct } from "../campaign/campaign.utils"
import PDFMemo from "./PDFMemo.component"
import { useTranslation } from "../translation/translate.component"
import PdfIcon from "../../components/pictos/pdfIcon.component"
import styled from "styled-components"

const ContractWrapper = styled.div`
  display: flex;
  margin: 32px 0;
  align-items: center;
`

const PdfIconWrappper = styled.div`
  margin-right: 10px;
  margin-top: 10px;
`

function DownloadQuotePdf ({ product, step1, step2, children, downloadSentence }) {
  const { t } = useTranslation()
  const [campaignProduct, campaignProductReady] = useCampaignProduct(product)

  if (campaignProductReady && campaignProduct) {
    return (
      <PDFMemo product={campaignProduct} step1={step1} step2={step2} downloadSentence={downloadSentence}>
        {children}
      </PDFMemo>
    )
  }

  return (
    <ContractWrapper>
      <PdfIconWrappper>
        <PdfIcon height={30} width={30} mr={5} />
      </PdfIconWrappper>
      {campaignProductReady && campaignProduct ? (
        <PDFMemo product={campaignProduct} step1={step1} step2={step2} downloadSentence={downloadSentence}>
          {children}
        </PDFMemo>
      ) : (
        <span>{t(`common:pdf.wait_document_creation`)}</span>
      )}
    </ContractWrapper>
  )
}

DownloadQuotePdf.propTypes = {
  product: PropTypes.object.isRequired,
  step1: PropTypes.object.isRequired,
  step2: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
  downloadSentence: PropTypes.string,
}

export default memo(DownloadQuotePdf)
