import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button } from "../structure/theme/button.component"
import { slugify } from "../../utils/format"
import Download from "../download/download.component"
import { useTranslation } from "../translation/translate.component"
import { useProductDocumentUrl } from "../product/product.utils"

const DownloadButton = styled(Button)`
  display: inline-block;
`

export default function DownloadPaymentSheet ({ product, onClick }) {
  const { t } = useTranslation()
  const paymentSheetUrl = useProductDocumentUrl(product?.id, `paymentSheet`)

  if (!product) {
    return null
  }

  const paymentSheetName = product ? `CJ-${slugify(product?.name)}.pdf` : ``

  return (
    <Download
      Component={DownloadButton}
      onClick={onClick}
      data-testid="download_payment_sheet"
      fileUrl={paymentSheetUrl}
      fileName={paymentSheetName}
    >
      {t(`common:payment.download_button`)}
    </Download>
  )
}

DownloadPaymentSheet.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
}
