import React, { useState } from "react"
import PropTypes from "prop-types"
import _noop from "lodash/noop"
import useNavigation from "../navigation/useNavigation"

export default function Download ({ Component, children, onClick = _noop, fileUrl, fileName, ...props }) {
  const [download, setDownload] = useState(0)
  const { resolveRouteToAbsolute } = useNavigation()

  const url = resolveRouteToAbsolute(`embeddedDownload`, null, {
    fileUrl,
    fileName,
  })

  function onClickDownload (e) {
    setDownload(download + 1)
    onClick(e)
  }

  const iframe = download > 0 ? <iframe width="0" height="0" frameBorder="0" src={url} key={download} /> : null

  return (
    <Component onClick={onClickDownload} data-testid={arguments[0][`data-testid`] || undefined}>
      {children}
      {iframe}
    </Component>
  )
}

Download.propTypes = {
  Component: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  fileUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
}
