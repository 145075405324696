import styled from "styled-components"
import React, { memo, useState, useEffect } from "react"
import { PDFDocument } from "pdf-lib"
import PropTypes from "prop-types"
import { slugify } from "../../utils/format"
import { colors } from "../structure/theme"
import DevOnly from "../../components/devOnly.component"
import { PdfBuilder } from "./pdf"
import { getWaterMeterPositions, usePrice, usePriceMention, useProductDocumentUrl } from "../product/product.utils"
import { sentryCaptureException } from "../thirdParties/sentry"
import { downloadFile } from "../../utils/fetch"
import { useTranslation } from "../translation/translate.component"

async function getPdfBlob (pdfDoc) {
  const pdfBytes = await pdfDoc.save()

  // eslint-disable-next-line no-undef
  const blob = new Blob([pdfBytes], { type: `application/pdf` })
  const blobUrl = URL.createObjectURL(blob)

  return {
    blob,
    url: blobUrl,
  }
}

async function getPdfPages (url, originalPdfDoc) {
  const pdf = await window.fetch(url)
  const pdfBytes = await pdf.arrayBuffer()
  const newPdfDoc = await PDFDocument.load(pdfBytes)

  return originalPdfDoc.copyPages(newPdfDoc, newPdfDoc.getPageIndices())
}

async function mergePdf (pdfDoc, sheetUrls) {
  const pdfs = await Promise.all(sheetUrls.map(sheetUrl => getPdfPages(sheetUrl, pdfDoc)))

  pdfs.forEach(pdf => {
    pdf.forEach(page => {
      pdfDoc.addPage(page)
    })
  })
}

async function getPdf (props, sheetUrls) {
  const { product } = props
  const pdfDoc = await PDFDocument.create()
  pdfDoc.setTitle(getDevisPdfName(product))

  const builder = new PdfBuilder(pdfDoc)
  await builder.build(props)
  await mergePdf(pdfDoc, sheetUrls)

  return getPdfBlob(pdfDoc)
}

function getDevisPdfName (product) {
  return `devis-${slugify(product.name)}`
}

const PDFDownloadLink = styled.span`
  color: ${colors(`primary`)};
  text-decoration: underline;
  cursor: pointer;
`

function PDF (props) {
  const { t } = useTranslation()
  const { product, step1, step2, children, downloadSentence = `en cliquant ici` } = props
  const { betterPrice, price, standardPrice, offerPrice, annualPurchaseOnly } = usePrice(product)

  const priceMention = usePriceMention(product)
  const waterMeterPosition = getWaterMeterPositions(t)
  const pdfProps = {
    product,
    step1,
    step2,
    betterPrice,
    price,
    standardPrice,
    offerPrice,
    priceMention,
    annualPurchaseOnly,
    waterMeterPosition,
    t,
  }
  const [pdf, setPdf] = useState(null)
  const [state, setState] = useState(`init`)
  const ipidSheet = useProductDocumentUrl(product.id, `ipidSheet`)
  const cguSheet = useProductDocumentUrl(product.id, `cguSheet`)

  useEffect(() => {
    if (!ipidSheet || !cguSheet || state !== `init`) {
      return
    }

    setState(`loading`)

    getPdf(pdfProps, [ipidSheet, cguSheet])
      .then(_pdf => {
        setPdf(_pdf)
        setState(_pdf ? `success` : `error`)
      })
      .catch(pdfError => {
        sentryCaptureException(pdfError)
        setState(`error`)
        throw pdfError
      })
  }, [state, ipidSheet, cguSheet])

  if (state === `init` || state === `loading`) {
    return <span>{t(`common:pdf.wait_loading`)}</span>
  }

  if (state === `error`) {
    return <span>{t(`common:pdf.wait_error`)}</span>
  }

  function onClick () {
    downloadFile(pdf.blob, `${getDevisPdfName(product)}.pdf`).catch(() => setState(`error`))
  }

  return (
    <span>
      {children}
      {`\u0020`}
      <PDFDownloadLink data-testid="recap_order" onClick={onClick}>
        {downloadSentence}
      </PDFDownloadLink>
      <DevOnly>
        <iframe src={pdf.url} width="100%" height="700px" />
      </DevOnly>
    </span>
  )
}

PDF.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  step1: PropTypes.object.isRequired,
  step2: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
  downloadSentence: PropTypes.string,
}

export default memo(PDF)
