import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Checkbox from "../../components/form/checkbox.component"
import { Button } from "../structure/theme/button.component"
import { useThemeContext } from "../structure/theme"
import Field from "../../components/form/field.component"
import { useTranslation } from "../translation/translate.component"
import { flexCenter } from "../structure/theme/styles.component"

const CloseWrapper = styled.div`
  ${flexCenter};
  margin: 16px 0;
`

const FieldWrapper = styled.div`
  display: flex;
  margin-top: 32px;
`

export default function ModalCheckbox ({ onClose, checkboxes }) {
  const {
    colors: { grey },
  } = useThemeContext()
  const { t } = useTranslation()

  return (
    <div data-testid={arguments[0][`data-testid`] || undefined}>
      {checkboxes.map(({ name, label }) => (
        <FieldWrapper key={name}>
          <Field component={Checkbox} name={name} label={label} color={grey.g800} />
        </FieldWrapper>
      ))}

      <CloseWrapper>
        <Button
          onClick={onClose}
          data-testid={arguments[0][`data-testid`] ? `${arguments[0][`data-testid`]}_close` : ``}
        >
          {t(`common:payment.close_button`)}
        </Button>
      </CloseWrapper>
    </div>
  )
}

ModalCheckbox.propTypes = {
  onClose: PropTypes.func.isRequired,
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired,
  ).isRequired,
}
