import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import ProductPreview from "../product/productPreview.component"
import Pen from "../../components/pictos/pen.component"
import SummaryLine from "./summaryLine.component"
import { formatAddress, formatNow } from "../../utils/format"
import {
  civilityToString,
  housingTypeToString,
  principalHousingToString,
  specificityToString,
  waterMeterPositionToString,
} from "../product/product.utils"
import { colors, fontSizes, useThemeContext } from "../structure/theme"
import breakpoint from "styled-components-breakpoint"
import Link from "../navigation/link.component"
import { useTranslation } from "../translation/translate.component"
import { Spacer } from "../structure/theme/styles.component"

const SubTitle = styled.div`
  font-size: ${fontSizes(`x_large`)};
  color: ${colors(`primary`)};
  display: ${props => (props.inline ? `inline-block` : `block`)};

  ${breakpoint(`small`)`
    font-size: ${fontSizes(`xx_large`)};
  `}
`

const Summary = styled.div`
  background-color: ${colors(`grey.g200`)};
  padding: 26px 10px 20px 10px;

  ${breakpoint(`small`)`
    padding: 26px 31px 20px 31px;
  `}
`

const ModifyLink = styled(Link)`
  color: ${colors(`primary`)};
  margin-left: 5px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`

export function PaymentSummary ({ product, step1, step2 }) {
  const { t } = useTranslation()
  const {
    colors: { primary },
  } = useThemeContext()

  return (
    <>
      <Summary>
        <ProductPreview product={product} />
      </Summary>

      <Title>
        <SubTitle inline>{t(`common:payment.section_stepper_title_step1`)}</SubTitle>
        <Spacer />
        <Pen size={20} color={primary} />
        <ModifyLink route="paymentStep1" data-testid="payment_link_step1">
          {t(`common:payment.page_step3_modify`)}
        </ModifyLink>
      </Title>

      <Summary>
        <SummaryLine
          label={t(`common:payment.page_step3_address`)}
          value={[
            formatAddress(step1.streetNumber, step1.street),
            step1.residence,
            `${step1.postalCode} ${step1.city}`,
          ]}
        />

        <SummaryLine
          label={t(`common:payment.page_step3_principal_housing`)}
          value={principalHousingToString(t, step1.principalHousing)}
        />

        <SummaryLine
          label={t(`common:payment.page_step3_property_type`)}
          value={specificityToString(t, step1.propertyType)}
        />

        <SummaryLine
          label={t(`common:payment.page_step3_specificity`)}
          value={specificityToString(t, step1.propertyType2)}
        />

        <SummaryLine
          label={t(`common:payment.page_step3_housing_type`)}
          value={housingTypeToString(t, step1.housingType)}
        />

        <SummaryLine
          label={t(`common:payment.page_step3_water_meter_position`)}
          value={waterMeterPositionToString(t, step1.waterMeterPosition)}
        />
      </Summary>

      <Title>
        <SubTitle inline>{t(`common:payment.page_step3_info_perso`)}</SubTitle>
        <Spacer />
        <Pen size={20} color={primary} />
        <ModifyLink route="paymentStep2" data-testid="payment_link_step2">
          {t(`common:payment.page_step3_modify`)}
        </ModifyLink>
      </Title>
      <Summary>
        <SummaryLine
          label={t(`common:payment.page_step3_identity`)}
          value={
            step2 &&
            step2.firstname &&
            step2.lastname &&
            `${civilityToString(step2.civility, t)} ${step2.firstname} ${step2.lastname}`
          }
        />
        <SummaryLine label={t(`common:payment.page_step3_phone`)} value={step2 && step2.phone} />
        <SummaryLine label={t(`common:payment.page_step3_email`)} value={step2 && step2.email} />
        <SummaryLine
          label={t(`common:payment.page_step3_billing_address`)}
          value={
            step2 &&
            step2.differentBilling && [
              formatAddress(step2.billingStreetNumber, step2.billingStreet),
              step2.billingResidence,
              `${step2.billingPostalCode} ${step2.billingCity}`,
          ]
          }
        />
      </Summary>

      <p>
        {APP_CONFIG.country !== `be` &&
          `${t(`common:payment.page_step3_subscription_date_mention`, {
            date: formatNow(),
          })} `}
        {t(`common:payment.page_step3_subscription_mention`)}
      </p>
    </>
  )
}

PaymentSummary.propTypes = {
  product: PropTypes.object,
  step1: PropTypes.object,
  step2: PropTypes.object,
}
