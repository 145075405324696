import { useEffect, useRef, useMemo, useCallback } from "react"
import PropType from "prop-types"
import { useFormikContext } from "formik"

export function useValueChanges (onChange, deps = []) {
  const _onChange = useCallback(onChange, deps)

  return useMemo(() => {
    return {
      name: `valueChanges`,
      Component: ValueChanges,
      isReady: true,
      props: { onChange: _onChange },
    }
  }, [_onChange])
}

export default function ValueChanges ({ onChange }) {
  const formik = useFormikContext()
  const prevValuesRef = useRef()
  const onChangeRef = useRef()
  onChangeRef.current = onChange

  useEffect(() => {
    const { values } = formik
    const { current: prevValues } = prevValuesRef

    if (prevValues) {
      for (const field in values) {
        if (values[field] !== prevValues[field]) {
          onChangeRef.current({
            field,
            value: values[field],
            prevValue: prevValues[field],
            formik,
          })
        }
      }
    }

    prevValuesRef.current = formik.values
  }, [formik.values])

  return null
}

ValueChanges.propTypes = {
  onChange: PropType.func.isRequired,
}
