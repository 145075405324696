import { rgb } from "pdf-lib"
import fontkit from "@pdf-lib/fontkit"
import { firstLetterUppercase, formatAddress, formatPriceAnnually, formatPriceMonthly } from "../../utils/format"
import {
  housingTypeToString,
  principalHousingToString,
  specificityToString,
  waterMeterPositionToString,
} from "../product/product.utils"
import { htmlToJson } from "../../utils/wysiwyg"

const pdfColor = {
  red: rgb(239 / 255, 67 / 255, 56 / 255),
  black: rgb(0, 0, 0),
  white: rgb(1, 1, 1),
  title: rgb(71 / 255, 162 / 255, 201 / 255),
  subtitle: rgb(184 / 255, 204 / 255, 228 / 255),
  proposal: rgb(162 / 255, 196 / 255, 201 / 255),
}

export class PdfBuilder {
  constructor (pdfDoc) {
    this.pdfDoc = pdfDoc
    this.margin = 15
    this._cursorY = 0
    this.pdfDoc.registerFontkit(fontkit)

    this.logo = this.loadImage(`${APP_CONFIG.basePath}/images/generated/logo.png`)
    this.picto = this.loadImage(`${APP_CONFIG.basePath}/images/pdf/check.png`)
    this.font = this.loadFont(`${APP_CONFIG.basePath}/fonts/Museosansrounded-500.otf`)
    this.fontBold = this.loadFont(`${APP_CONFIG.basePath}/fonts/Museosansrounded-900.otf`)
    this.createPage()
  }

  set cursorY (height) {
    if (height < this.margin * 2) {
      this.createPage(4)
    } else {
      this._cursorY = height
    }
  }

  get cursorY () {
    return this._cursorY
  }

  createPage (marginMultiplicator = 1) {
    this.page = this.pdfDoc.addPage()
    const { width, height } = this.page.getSize()
    this.cursorX = 0
    this.width = width
    this.height = height
    this.cursorY = this.height - this.margin * marginMultiplicator
  }

  load (path) {
    return window.fetch(path).then(response => response.arrayBuffer())
  }

  loadImage (path) {
    return this.load(path).then(arrayBuffer => this.pdfDoc.embedPng(arrayBuffer))
  }

  loadFont (path) {
    return this.load(path).then(arrayBuffer => this.pdfDoc.embedFont(arrayBuffer))
  }

  loadResources () {
    // download logo
    return Promise.all([this.logo, this.picto, this.font, this.fontBold]).then(([logo, picto, font, fontBold]) => {
      this.logo = logo
      this.picto = picto
      this.font = font
      this.fontBold = fontBold
    })
  }

  renderTextLine (text, x, textHeight, size, font, color, underline, lineThrough, prepareY = true) {
    const lines = text.split(`\n`)
    lines.forEach((line, i) => {
      if (!line) {
        return
      }
      const y = this.cursorY - textHeight
      const width = font.widthOfTextAtSize(line, size)
      this.page.drawText(line, {
        x,
        y,
        size,
        font: font,
        color,
      })
      if (underline) {
        const height = size / 5
        const yUnderline = y - height
        this.page.drawRectangle({
          x,
          y: yUnderline,
          width,
          height: height / 2,
          color,
        })
      }
      if (lineThrough) {
        const height = size / 5
        const yLineThrough = y + height
        this.page.drawRectangle({
          x,
          y: yLineThrough,
          width,
          height: height / 2,
          color,
        })
      }
      this.cursorX = x + width
      if (prepareY || i < lines.size) {
        this.cursorY = y
        this.cursorX = this.margin
      }
    })
  }

  renderText (text, params = {}) {
    const {
      size = 12,
      color = pdfColor.black,
      maxWidth = this.width - this.margin,
      x = this.margin,
      center = false,
      bold = false,
      underline = false,
      lineThrough = false,
      prepareY = true,
      resetXonNewLine = false,
    } = params
    let localCursorX = x
    let MaxWidth = maxWidth - localCursorX
    const font = bold ? this.fontBold : this.font
    const textHeight = font.heightAtSize(size)
    const textWidth = font.widthOfTextAtSize(text, size)
    if (textWidth > MaxWidth) {
      const words = text.split(` `)
      let line = ``
      words.forEach(_word => {
        const word = _word || ` `
        const nextLine = line ? `${line} ${word}` : word
        const nextLineWidth = font.widthOfTextAtSize(nextLine, size)
        if (nextLineWidth > MaxWidth) {
          const xLine = center ? this.width / 2 - font.widthOfTextAtSize(line, size) / 2 : localCursorX
          this.renderTextLine(line, xLine, textHeight, size, font, color, underline, lineThrough, true)
          line = word
          if (resetXonNewLine) {
            localCursorX = this.margin
            MaxWidth = maxWidth - localCursorX
          }
        } else {
          line = nextLine
        }
      })
      if (line) {
        const xLine = center ? this.width / 2 - font.widthOfTextAtSize(line, size) / 2 : localCursorX
        this.renderTextLine(line, xLine, textHeight, size, font, color, underline, lineThrough, prepareY)
      }
    } else {
      this.renderTextLine(
        text,
        center ? this.width / 2 - textWidth / 2 : localCursorX,
        textHeight,
        size,
        font,
        color,
        underline,
        lineThrough,
        prepareY,
      )
    }
  }

  renderMultiTexts (texts) {
    texts.forEach(({ text, prepareY = false, ...params }, i) => {
      const x = i === 0 ? this.margin : this.cursorX
      this.renderText(text, {
        x,
        ...params,
        prepareY: prepareY || i + 1 === texts.length,
        resetXonNewLine: true,
      })
    })
  }

  renderImage (image, params = {}) {
    const { x = this.margin, y = this.cursorY, width = 0, height = 0 } = params
    let scale = 1
    if (width) {
      scale = width / image.width
    } else if (height) {
      scale = height / image.height
    }
    const pngDims = image.scale(scale)
    this.page.drawImage(image, {
      x: x,
      y: y - pngDims.height,
      width: pngDims.width,
      height: pngDims.height,
    })
    if (y === this.cursorY) {
      this.cursorY -= pngDims.height
    }
  }

  renderTitle (titleText) {
    const size = 19
    const titlePadding = 10
    const textHeight = this.fontBold.heightAtSize(size)
    const height = textHeight + titlePadding * 2
    this.page.drawRectangle({
      width: this.width - 2 * this.margin,
      height: height,
      x: this.margin,
      y: this.cursorY - height,
      color: pdfColor.title,
    })
    this.renderSpace((titlePadding * 2) / 5)
    this.renderText(titleText.toUpperCase(), {
      size,
      color: pdfColor.white,
      center: true,
      bold: true,
    })
    this.renderSpace((titlePadding * 4) / 5)
  }

  renderSubtitle (titleText, color = pdfColor.subtitle) {
    const size = 10
    const titlePadding = 7
    const titleMarginTop = 15
    const textHeight = this.font.heightAtSize(size)
    const height = textHeight + titlePadding * 2
    this.renderSpace(titleMarginTop)
    this.cursorY -= height
    this.page.drawRectangle({
      width: this.width - 2 * this.margin,
      height: height,
      x: this.margin,
      y: this.cursorY,
      color,
    })
    this.cursorY += (height * 5) / 6
    this.renderText(titleText.toUpperCase(), {
      size,
      x: this.margin * 2,
      bold: true,
    })
    this.renderSpace((titlePadding * 4) / 5)
  }

  renderSpace (space) {
    this.cursorY -= space
  }

  renderRow (row1Text, row2Text) {
    if (!row1Text || !row2Text) {
      return
    }
    this.renderSpace(5)
    const originalHeight = this.cursorY
    const maxWidth = (this.width - this.margin * 3) / 2
    this.renderText(`${row1Text}:`, { maxWidth, bold: true })

    const heightRow1 = this.cursorY
    this.cursorY = originalHeight
    if (Array.isArray(row2Text)) {
      row2Text.forEach(text => {
        this.renderText(text, {
          x: this.width / 2 + this.margin / 2,
        })
      })
    } else {
      this.renderText(row2Text, {
        x: this.width / 2 + this.margin / 2,
      })
    }

    this.cursorY = Math.min(this.cursorY, heightRow1)
  }

  renderLogement ({ step1, t }) {
    this.renderSubtitle(`logement à couvrir`)
    this.renderRow(`Adresse postale couverte`, [
      formatAddress(step1.streetNumber, step1.street),
      step1.residence,
      `${step1.postalCode} ${step1.city}`,
    ])
    this.renderRow(`Résidence principale`, principalHousingToString(t, step1.principalHousing))
    this.renderRow(`Statut de l'occupant`, specificityToString(t, step1.propertyType, true))
    this.renderRow(`Situation vis-à-vis du logement`, specificityToString(t, step1.propertyType2, true))
    this.renderRow(`Type de logement`, housingTypeToString(t, step1.housingType))
    this.renderRow(`Votre compteur d'eau se situe`, waterMeterPositionToString(t, step1.waterMeterPosition))
  }

  renderInformations ({ step1, step2 }) {
    const fullName = `${firstLetterUppercase(step2.civility)} ${step2.firstname} ${step2.lastname}`
    this.renderSubtitle(`Informations personnelles`)
    this.renderRow(`Civilité, prénom, nom`, fullName)
    this.renderRow(`Numéro de téléphone`, step2.phone)
    this.renderRow(`Adresse e-mail`, step2.email)
    this.renderRow(
      `Adresse de facturation`,
      step2.differentBilling
        ? [
            formatAddress(step2.billingStreetNumber, step2.billingStreet),
            step2.billingResidence,
            `${step2.billingPostalCode} ${step2.billingCity}`,
          ]
        : [step1.address, step1.residence, `${step1.postalCode} ${step1.city}`],
    )
  }

  renderSouscription () {
    const date = new Date()

    this.renderSubtitle(`Date de souscription`)
    this.renderSpace(5)
    this.renderText(date.toLocaleDateString(), { size: 15, bold: true })
    this.renderText(`(sous réserve du paiement de la commande à cette date)`)
    this.renderText(
      `Pour rappel, vous disposez d’un délai de renonciation de 28 jours à compter de la date de souscription.`,
    )
    this.renderSpace(15)
    this.renderText(
      `Pour connaitre les conditions applicables à votre contrat et notamment la date d’effet du contrat, la date de démarrage des garanties, les exclusions et garanties, la durée et le droit de renonciation, veuillez consulter les Conditions Générales applicables ci-après.`,
    )
  }

  renderProposal ({ t, step1, product, price, betterPrice, standardPrice, priceMention, annualPurchaseOnly }) {
    this.renderSubtitle(`notre proposition`, pdfColor.proposal)
    this.renderSpace(15)
    this.renderText(
      `Après avoir analysé votre situation, nous vous proposons en adéquation avec vos besoins, la souscription  du contrat suivant:`,
      {
        center: true,
        bold: true,
      },
    )
    this.renderText(product.impactProduct.name, {
      center: true,
      bold: true,
      size: 15,
      color: pdfColor.red,
    })
    this.renderSpace(15)
    this.renderText(`VOTRE BESOIN :`, {
      color: pdfColor.red,
      bold: true,
      underline: true,
    })
    const whoAreYou = specificityToString(t, step1.propertyType || step1.propertyType2)
    this.renderText(
      `Vous habitez en ${housingTypeToString(
        t,
        step1.housingType,
      ).toLowerCase()}, et en tant que ${whoAreYou} vous souhaitez un contrat qui couvre l’entretien des installations de votre logement, car vous ne détenez pas encore de Contrat d’Assistance d’urgence 24h/24 à ce titre.`,
    )

    this.renderSpace(15)
    this.renderMultiTexts([
      {
        text: `NOTRE SOLUTION :`,
        color: pdfColor.red,
        bold: true,
        underline: true,
      },
      {
        text: ` garanties et plafonds`,
        color: pdfColor.red,
      },
    ])

    const json = htmlToJson(product.universDescription)
    const universDescription = this.mapJson(json)
    this.renderMultiTexts(universDescription)

    this.renderSpace(15)
    this.renderText(`COTISATION :`, {
      color: pdfColor.red,
      bold: true,
      underline: true,
    })

    const formatPrice = annualPurchaseOnly ? formatPriceAnnually : formatPriceMonthly
    const recurrence = annualPurchaseOnly ? `an` : `mois`

    const cotisationTexts = [
      {
        text: `Une cotisation totale mensuelle de : `,
        bold: true,
      },
      {
        text: `${formatPrice(price)}€ TTC/${recurrence}`,
        color: pdfColor.red,
        bold: true,
      },
    ]

    if (betterPrice) {
      cotisationTexts.push(
        {
          text: ` au lieu de `,
          bold: true,
        },
        {
          text: `${formatPrice(standardPrice)}€ TTC/${recurrence} ¹`,
          lineThrough: true,
          bold: true,
        },
      )
    }
    cotisationTexts.push({
      text: ` payable par prélèvement bancaire ou par carte de crédit`,
      bold: true,
    })
    this.renderMultiTexts(cotisationTexts)
    this.renderSpace(12)
    this.renderText(priceMention, { size: 10 })

    this.renderSpace(12)
    const pictoWidth = 40
    const originalHeight = this.cursorY
    this.renderImage(this.picto, { width: pictoWidth })
    const params = {
      x: pictoWidth + this.margin * 2,
      maxWidth: this.width - pictoWidth - this.margin * 2,
    }
    const heightPicto = this.cursorY
    this.cursorY = originalHeight
    this.renderText(
      `Nous vous confirmons donc l’adéquation du contrat à vos exigences et besoins préalablement identifiés`,
      { ...params, size: 12, bold: true },
    )
    this.cursorY = Math.min(heightPicto, this.cursorY)
    this.renderSpace(12)
    this.renderText(
      `Vous trouverez ci-après le Document d’Information ainsi que les Conditions Générales de votre contrat.`,
      { size: 12, bold: true, color: pdfColor.red },
    )
  }

  mapJson (json) {
    const textsToRender = []
    const initialParams = {
      size: 12,
      color: pdfColor.black,
      bold: false,
    }

    const treeJson = (object, params) => {
      if (!object.content) {
        return null
      }
      for (let i = 0; i < object.content.length; i++) {
        const content = object.content[i]
        if (content.type) {
          const newParams = {
            ...params,
            prepareY: i + 1 === object.content.length ? params.prepareY : false,
          }
          const newContent = { ...content }
          switch (content.type) {
            case `parsererror`: {
              continue
            }
            case `strong`:
            case `b`: {
              newParams.bold = true
              break
            }
            case `ul`: {
              newParams.prepareY = true
              break
            }
            case `li`: {
              textsToRender.push({
                text: `• `,
                ...params,
                prepareY: false,
              })
              newParams.prepareY = true
              break
            }
            case `p`: {
              newParams.prepareY = true
              break
            }
          }
          treeJson(newContent, newParams)
        } else {
          textsToRender.push({
            text: content,
            ...params,
          })
        }
      }
    }

    treeJson(json, initialParams)

    return textsToRender
  }

  populate (props) {
    this.renderImage(this.logo, { height: 50 })
    this.renderSpace(12)
    this.renderTitle(`récapitulatif de votre devis et de votre situation`)
    this.renderSpace(10)
    this.renderLogement(props)
    this.renderInformations(props)
    this.renderProposal(props)
    this.renderSouscription(props)
  }

  async build (props) {
    await this.loadResources(props)
    this.populate(props)
  }
}
