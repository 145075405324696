export function isWysiwygEmpty (wysiwyg) {
  return !wysiwyg || wysiwyg.replace(/<[^>]*>/g, ``).length <= 0
}

function treeHTML (element, object) {
  object.type = element.nodeName.toLowerCase()
  const nodeList = element.childNodes
  if (nodeList && nodeList.length) {
    object.content = []
    for (let i = 0; i < nodeList.length; i++) {
      // eslint-disable-next-line no-undef
      if (nodeList[i].nodeType === 3) {
        object.content.push(nodeList[i].nodeValue)
      } else {
        object.content.push({})
        treeHTML(nodeList[i], object.content[object.content.length - 1])
      }
    }
  }
  if (element.attributes && element.attributes.length) {
    object.attributes = {}
    for (let i = 0; i < element.attributes.length; i++) {
      object.attributes[element.attributes[i].nodeName] = element.attributes[i].nodeValue
    }
  }
}

function getDomParser () {
  let DomParser = null
  if (SERVER) {
    const JSDOM = require(`jsdom`).JSDOM
    const jsDomInstance = new JSDOM()
    DomParser = jsDomInstance.window.DOMParser
  } else {
    DomParser = window.DOMParser
  }

  return new DomParser()
}

// eslint-disable-next-line no-undef
export function htmlToJson (html) {
  const parser = getDomParser()
  const treeObject = {}

  const document = parser.parseFromString(
    `<div data-testid="wysiwyg">${(html || ``).replace(/<br>/g, `<br/>`).replace(/&nbsp;/g, ` `)}</div>`,
    `text/html`,
  )
  html = document.body.firstChild

  treeHTML(html, treeObject)

  return treeObject
}
