import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { fontSizes } from "../modules/structure/theme"

const Wrapper = styled.div`
  border: 1px dotted red;
  width: 100%;
  padding: 10px;
`
const Label = styled.div`
  color: red;
  font-size: ${fontSizes(`xx_small`)};
  padding-bottom: 10px;
`

export default function DevOnly ({ children }) {
  if (process.env.NODE_ENV === `production`) {
    return null
  }

  return (
    <Wrapper>
      <Label>Only appear in dev mode</Label>
      {children}
    </Wrapper>
  )
}

DevOnly.propTypes = {
  children: PropTypes.node.isRequired,
}
