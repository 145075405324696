import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function Pen ({ size = `100%`, ...props }) {
  return (
    <Picto
      viewbox="0 0 200 200"
      size={size}
      d={[
        `M2,144.5V198h53.5L162.6,90.9l-53.5-53.5L2,144.5z M48.7,181.5H35v-16.5H18.5v-13.8l11.7-11.7l30.2,30.2   L48.7,181.5z M113.2,59.2c1.9,0,2.8,0.9,2.8,2.8c0,0.9-0.3,1.6-0.9,2.2L45.4,134c-0.6,0.6-1.3,0.9-2.2,0.9c-1.9,0-2.8-0.9-2.8-2.8   c0-0.9,0.3-1.6,0.9-2.2L111,60.2C111.6,59.5,112.3,59.2,113.2,59.2L113.2,59.2z M113.2,59.2`,
        `M192.2,38L162,7.9c-3.3-3.3-7.2-4.9-11.7-4.9c-4.6,0-8.5,1.6-11.6,4.9l-21.4,21.2l53.5,53.5l21.4-21.4   c3.2-3.2,4.8-7,4.8-11.6C197,45.3,195.4,41.4,192.2,38L192.2,38z M192.2,38`,
      ]}
      {...props}
    />
  )
}

Pen.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
