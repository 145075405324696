import React from "react"
import PropTypes from "prop-types"

export default function PdfIcon ({
  link = false,
  color1 = `white`,
  color2 = `grey`,
  color3 = `red`,
  color4 = `red`,
  size = 100,
  ...props
}) {
  const id = `pdf-icon`

  if (link) {
    return (
      <svg width={size} height={size}>
        <use xlinkHref={`#${id}`} />
      </svg>
    )
  }

  return (
    <svg id={id} viewBox="0 0 327.2 423.5" fill={color1} width={size} height={size} {...props}>
      <path d="M296.5,379H73.5a19.06,19.06,0,0,1-19-19V36a19.06,19.06,0,0,1,19-19H253.13q14.53,15.85,29.25,31.77Q299,66.7,315.5,84.39V360A19.06,19.06,0,0,1,296.5,379Z" />
      <path
        d="M240.5,1.8H73.3c-18.5,0-33.6,16.1-33.6,36V70.4H54.8V37.7c0-11.5,8.3-20.9,18.5-20.9H229.6V84.3c0,14.6,11.1,26.4,24.8,26.4h61.2V357.5c0,11.5-8.3,20.9-18.5,20.9H275.5l-11.6,15.1h33.2c18.5,0,33.6-16.1,33.6-36V100.3Zm13.9,93.9c-5.4,0-9.7-5.1-9.7-11.3V28.6L306,95.7H254.4Zm-83,282.8H73.3c-10.2,0-18.5-9.4-18.5-20.9V175.4h-15V357.6c0,19.8,15.1,36,33.6,36H183.1Z"
        fill={color2}
      />
      <rect y="79" width="200.1" height="84" fill={color3} />
      <path
        d="M159.8,333.1a4.86,4.86,0,0,0-4.4,2.8,5.12,5.12,0,0,0,.5,5.2l63.6,82.3a5,5,0,0,0,7.8,0l63.6-82.3a4.71,4.71,0,0,0,.5-5.2,5,5,0,0,0-4.4-2.8H263.6a2.48,2.48,0,0,1-2.5-2.5V242.5a5,5,0,0,0-5-5H190.6a5,5,0,0,0-5,5v88.1a2.48,2.48,0,0,1-2.5,2.5H159.8Z"
        fill={color4}
      />
      <path d="M35.6,96.4a106.6,106.6,0,0,1,16.6-1.1c7.6,0,12.9,1.4,16.6,4.3a15.6,15.6,0,0,1,5.8,12.5,17.56,17.56,0,0,1-5,12.9c-4.2,3.9-10.4,5.7-17.6,5.7-1.6,0-3.1-.1-4.2-0.2v19.4H35.6V96.4ZM47.8,121a21.45,21.45,0,0,0,4.1.3c6.5,0,10.5-3.3,10.5-8.8,0-5-3.5-8-9.6-8a18.34,18.34,0,0,0-5.1.5v16h0.1Z" />
      <path d="M82.3,96.4a113.3,113.3,0,0,1,16.6-1.1c10.3,0,17,1.8,22.2,5.8,5.6,4.2,9.2,10.8,9.2,20.4,0,10.4-3.8,17.5-9,21.9-5.7,4.7-14.4,7-25,7a106.42,106.42,0,0,1-13.9-.8V96.4H82.3Zm12.3,44.2a26.89,26.89,0,0,0,4.3.2c11.1,0.1,18.3-6,18.3-19,0.1-11.3-6.5-17.2-17-17.2a21.4,21.4,0,0,0-5.5.5v35.5H94.6Z" />
      <path d="M138.3,95.7h33.1v10H150.6v12.4h19.5v10H150.6v21.8H138.3V95.7h0Z" />
    </svg>
  )
}

PdfIcon.propTypes = {
  link: PropTypes.bool,
  color1: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
  color4: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
