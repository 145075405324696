import React, { useState, useEffect } from "react"

export default function EllipsisLoader () {
  const [nb, setNb] = useState(0)

  useEffect(() => {
    function tick () {
      setNb(iNb => iNb + 1)
    }
    const id = setInterval(tick, 300)

    return () => clearInterval(id)
  }, [])

  return <span>{`.`.repeat(1 + (nb % 3))}</span>
}
