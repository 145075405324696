import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import TunnelCustomer from "../tunnel/customer/tunnelCustomer.component"
import darken from "polished/lib/color/darken"
import { usePaymentContext } from "./payment.provider"
import { Bold } from "../structure/theme/styles.component"
import Checkbox from "../../components/form/checkbox.component"
import DownloadPaymentSheet from "./downloadPaymentSheet.component"
import { createHomeServeLink } from "../navigation/navigation.utils"
import breakpoint from "styled-components-breakpoint"
import { colors, fontSizes, useThemeContext } from "../structure/theme"
import DownloadQuotePdfLazy from "../quote/downloadQuotePdfLazy.component"
import DevOnly from "../../components/devOnly.component"
import { ModalButton } from "../../components/modal/modal.component"
import ModalCheckbox from "./modalCheckbox.component"
import { addEventTracking, usePaymentProductTracking, useViewPageTracking } from "../thirdParties/gtm/tagManager"
import { submitStep3 } from "./payment.api"
import { sentryCaptureException } from "../thirdParties/sentry"
import Form from "../../components/form/form.component"
import Field from "../../components/form/field.component"
import useNavigation from "../navigation/useNavigation"
import { Trans, useTranslation } from "../translation/translate.component"
import { usePaymentErrorData } from "./payment.utils"
import { PaymentSummary } from "./paymentSummary.component"
import { useFormikContext } from "formik"
import { useCaptcha } from "../../components/form/plugins/captcha.component"
import { useValueChanges } from "../../components/form/plugins/valueChanges.component"
import SubmitButton from "../../components/form/submitButton.component"

const Order = styled.div`
  background-color: ${colors(`primary`, darken(0.15))};
  color: white;
  padding: 12.5px 30px;
  font-size: ${fontSizes(`large`)};
`

const StepNumber = styled.span`
  text-align: center;
  width: 51px;
  height: 51px;
  background-color: ${colors(`primary`)};
  color: white;
  font-size: ${fontSizes(`xxx_large`)};
  border-radius: 100%;
  margin: 10px;
`

const Step3Link = styled.a`
  color: ${colors(`primary`)};
  cursor: pointer;
  text-decoration: underline;
`

const StepBackgroundDisabled = styled.div`
  position: absolute;
  top: calc(51px + 20px);
  width: 100%;
  height: calc(100% - 51px - 20px);
  ${breakpoint(`small`)`
    top: 0;
    left: calc(51px + 20px);
    width: calc(100% - 51px - 20px);
    height: 100%;
  `};
  padding: 36px 27px;
  background-image: repeating-linear-gradient(
    135deg,
    ${colors(`transparent.medium`)},
    ${colors(`transparent.medium`)} 10px,
    ${colors(`grey.mediumTransparent`)} 10px,
    ${colors(`grey.mediumTransparent`)} 40px
  );
  z-index: 1;
`

const StepBox = styled.div`
  position: relative;
  flex: 1;
  border: 1px solid ${colors(`withBorder`, `grey.g800`, `transparent`)};
  padding: 29px 30px 26px 30px;
`

const DisabledText = styled.span`
  color: ${colors(`disabled`, `grey.g600`)};
`

const OfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Offer = styled.div`
  margin-top: 32px;
`

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  ${breakpoint(`small`)`
    flex-direction: row;
  `};

  &:nth-of-type(2n) {
    margin: 16px 0;
  }
`

const LegalSubTitle = styled.div`
  font-size: ${fontSizes(`x_large`)};
  color: ${colors(`primary`)};

  ${breakpoint(`small`)`
    font-size: ${fontSizes(`xx_large`)};
    margin-bottom: 15px;
  `}
`

function Step ({ stepNumber, children }) {
  const { values } = useFormikContext()

  return (
    <StepWrapper>
      <StepNumber>{stepNumber}</StepNumber>
      {typeof children === `function` ? children(values) : children}
    </StepWrapper>
  )
}

Step.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
}

const formInitialState = {
  conditionCGVChecked: false,
  optinEmailOffComPartner: false,
  optinEmailSmsComIsChecked: false,
  optinMailPhoneRefuseIsChecked: false,
  optinComRefusePartnerIsChecked: false,
}

const checkBoxToReverse = [`optinEmailSmsComIsChecked`]

function validate () {
  return {}
}

export default function Step3Page () {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const paymentContext = usePaymentContext()
  const {
    colors: { grey },
  } = useThemeContext()
  const { isReady, sessionId } = paymentContext

  const step1 = isReady && paymentContext.step1 ? paymentContext.step1 : {}
  const step2 = isReady && paymentContext.step2 ? paymentContext.step2 : {}
  const product = isReady && paymentContext.product ? paymentContext.product : null
  const [downloaded, setDownloaded] = useState(false)
  const [btnActive, setBtnActive] = useState(true)
  const paymentErrorData = usePaymentErrorData(product)

  useViewPageTracking(`TunnelCommande`, ``, `Tunnel - Recapitulatif`)
  usePaymentProductTracking(product, 3, `Recapitulatif`)

  const captcha = useCaptcha()
  const valueChanges = useValueChanges(({ field, value, formik: { setFieldValue } }) => {
    if (APP_CONFIG.country === `be` && field === `optinEmailSmsComIsChecked`) {
      setFieldValue(`optinComRefusePartnerIsChecked`, value)
    }
  }, [])

  function onSubmit ({ captchaToken, ...values }) {
    setBtnActive(false)

    return submitStep3(sessionId, revertCheckboxes(values), captchaToken)
  }

  function onSubmitSuccess () {
    setBtnActive(true)
    navigate(`paymentStep4`)
  }

  function onSubmitFail (values, actions, error) {
    sentryCaptureException(error, paymentErrorData)
    setBtnActive(true)
  }

  function revertCheckboxes (values) {
    const newVal = { ...values }
    checkBoxToReverse.forEach(checkboxName => {
      newVal[checkboxName] = !newVal[checkboxName]
    })

    return newVal
  }

  const hasPartners = APP_CONFIG.country === `fr`

  const checkboxes = [
    {
      name: `optinMailPhoneRefuseIsChecked`,
      label: t(
        APP_CONFIG.country === `be`
          ? `common:payment.page_step3_optin_mail_phone_label_be`
          : `common:payment.page_step3_optin_mail_phone_label`,
      ),
    },
  ]

  if (APP_CONFIG.country === `fr`) {
    checkboxes.push({
      name: `optinComRefusePartnerIsChecked`,
      label: (
        <Trans
          t={t}
          i18nKey="common:payment.page_step3_optin_com_label"
          components={{
            link: <Step3Link href={createHomeServeLink(APP_CONFIG.homeserve.partenaires)} target="_blank" />,
          }}
        />
      ),
    })
  }

  return (
    <TunnelCustomer currentStep={2} title={t(`common:payment.page_step3_payment_title`)} product={product}>
      <Order>{t(`common:payment.sidebar_title`)}</Order>
      <PaymentSummary product={product} step1={step1} step2={step2} />

      <LegalSubTitle>{t(`common:payment.page_step3_legal_three_steps`)}</LegalSubTitle>

      <Form
        name="tunnel_customer_step3"
        initialValues={formInitialState}
        captcha={captcha}
        valueChanges={valueChanges}
        validate={validate}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        onSubmitFail={onSubmitFail}
      >
        <Step stepNumber={1}>
          <StepBox withBorder>
            <div>
              <Trans t={t} i18nKey="common:payment.page_step3_download_legal_doc" components={{ bold: <Bold /> }} />
              <br />
              <br />
            </div>
            <DownloadPaymentSheet
              product={product}
              onClick={() => {
                setDownloaded(true)
                addEventTracking(`eventGA`, {
                  eventCategory: `Tunnel - Recapitulatif`,
                  eventAction: `Clic`,
                  eventLabel: `Conditions juridiques`,
                })
              }}
            />
            <DevOnly>
              <a onClick={() => setDownloaded(true)}>Cliquez ici pour passer et ne pas télécharger les documents</a>
            </DevOnly>
          </StepBox>
        </Step>

        <Step stepNumber={2}>
          {values => (
            <>
              {!(downloaded || values.conditionCGVChecked) && (
                <StepBackgroundDisabled data-testid="payment_disabled_step2" />
              )}
              <StepBox withBorder={downloaded || values.conditionCGVChecked}>
                <Field
                  component={Checkbox}
                  name="conditionCGVChecked"
                  label={
                    <DisabledText disabled={!(downloaded || values.conditionCGVChecked)}>
                      {t(
                        APP_CONFIG.country === `be`
                          ? `common:payment.page_step3_check_legal_doc_read_be`
                          : `common:payment.page_step3_check_legal_doc_read`,
                      )}
                    </DisabledText>
                  }
                />
              </StepBox>
            </>
          )}
        </Step>

        <Step stepNumber={3}>
          {values => (
            <>
              {!values.conditionCGVChecked && <StepBackgroundDisabled data-testid="payment_disabled_step3" />}
              <StepBox>
                <Field
                  component={Checkbox}
                  name="optinEmailOffComPartner"
                  label={
                    <DisabledText disabled={!values.conditionCGVChecked}>
                      {hasPartners ? (
                        <Trans
                          t={t}
                          i18nKey="common:payment.page_step3_optin_email_partner_label"
                          components={{
                            link: (
                              <Step3Link href={createHomeServeLink(APP_CONFIG.homeserve.partenaires)} target="_blank" />
                            ),
                          }}
                        />
                      ) : (
                        t(`common:payment.page_step3_optin_email_group_label`)
                      )}
                    </DisabledText>
                  }
                  color={grey.g800}
                />
                {isReady && (
                  <DownloadQuotePdfLazy product={product} step1={step1} step2={step2}>
                    {t(`common:payment.page_step3_download_quote`)}
                  </DownloadQuotePdfLazy>
                )}
                <DisabledText disabled={!values.conditionCGVChecked}>
                  {t(`common:payment.page_step3_submit_mention`)}
                  <br />
                  <br />
                </DisabledText>
                <SubmitButton data-testid="tunnel_customer_step3_submit" disabled={!btnActive}>
                  {t(`common:payment.page_step3_submit`)}
                </SubmitButton>
              </StepBox>
            </>
          )}
        </Step>

        <OfferWrapper>
          <Offer>
            {t(
              APP_CONFIG.country === `be`
                ? `common:payment.page_step3_optin_email_sms_open_be`
                : `common:payment.page_step3_optin_email_sms_open`,
            )}
            <ModalButton
              modalComponent={ModalCheckbox}
              modalProps={{
                "data-testid": `modal_email_sms`,
                checkboxes: [
                  {
                    name: `optinEmailSmsComIsChecked`,
                    label: t(
                      APP_CONFIG.country === `be`
                        ? `common:payment.page_step3_optin_email_sms_label_be`
                        : `common:payment.page_step3_optin_email_sms_label`,
                    ),
                  },
                ],
              }}
            >
              {(onClick, ref) => (
                <Step3Link ref={ref} onClick={onClick} data-testid="modal_email_sms_button">
                  {t(`common:payment.page_step3_optin_open`)}
                </Step3Link>
              )}
            </ModalButton>
          </Offer>

          <Offer>
            {t(
              APP_CONFIG.country === `be`
                ? `common:payment.page_step3_optin_mail_phone_open_be`
                : `common:payment.page_step3_optin_mail_phone_open`,
            )}
            <ModalButton
              modalComponent={ModalCheckbox}
              modalProps={{
                "data-testid": `modal_mail_phone`,
                checkboxes,
              }}
            >
              {(onClick, ref) => (
                <Step3Link ref={ref} onClick={onClick} data-testid="modal_mail_phone_button">
                  {t(`common:payment.page_step3_optin_open`)}
                </Step3Link>
              )}
            </ModalButton>
          </Offer>
        </OfferWrapper>
      </Form>
    </TunnelCustomer>
  )
}
